import React from 'react'

export default function Svg() {
    return (
        <div>
<svg width="600" height="600" viewBox="0 0 1135 813" fill="none" xmlns="http://www.w3.org/2000/svg">
<g id="chart">
<path id="Vector" opacity="0.3" d="M772.437 60.8815H691.813V96.6163H772.437V60.8815Z" fill="#63C1FF"/>
<path id="Vector_2" opacity="0.3" d="M906.44 0H825.816V35.7348H906.44V0Z" fill="#63C1FF"/>
<path id="Vector_3" opacity="0.3" d="M912 110.513H831.376V146.248H912V110.513Z" fill="#63C1FF"/>
<path id="Vector_4" opacity="0.3" d="M654.003 151.542H573.379V187.277H654.003V151.542Z" fill="#63C1FF"/>
<path id="Vector_5" opacity="0.3" d="M628.426 298.452H547.802V334.186H628.426V298.452Z" fill="#63C1FF"/>
<path id="Vector_6" opacity="0.3" d="M750.196 295.143H669.572V330.878H750.196V295.143Z" fill="#63C1FF"/>
<path id="Vector_7" d="M825.816 17.642L772.437 77.9212L831.376 128.381" stroke="#3F3D56" strokeMiterlimit="10"/>
<path id="Vector_8" d="M691.813 77.9217L613.691 151.542" stroke="#3F3D56" strokeMiterlimit="10"/>
<path id="Vector_9" d="M588.114 298.424L613.691 187.276L709.884 295.142" stroke="#3F3D56" strokeMiterlimit="10"/>
<path id="Vector_10" opacity="0.3" d="M228.635 97.695H148.011V133.43H228.635V97.695Z" fill="#63C1FF"/>
<path id="Vector_11" opacity="0.3" d="M362.638 36.8135H282.014V72.5483H362.638V36.8135Z" fill="#63C1FF"/>
<path id="Vector_12" opacity="0.3" d="M368.198 147.327H287.574V183.061H368.198V147.327Z" fill="#63C1FF"/>
<path id="Vector_13" opacity="0.3" d="M110.201 188.356H29.5773V224.09H110.201V188.356Z" fill="#63C1FF"/>
<path id="Vector_14" opacity="0.3" d="M84.6239 335.265H4V371H84.6239V335.265Z" fill="#63C1FF"/>
<path id="Vector_15" opacity="0.3" d="M206.394 331.956H125.77V367.691H206.394V331.956Z" fill="#63C1FF"/>
<path id="Vector_16" d="M282.014 54.4555L228.635 114.735L287.573 165.194" stroke="#3F3D56" strokeMiterlimit="10"/>
<path id="Vector_17" d="M148.011 114.735L69.8892 188.356" stroke="#3F3D56" strokeMiterlimit="10"/>
<path id="Vector_18" d="M44.3122 335.237L69.8889 224.09L166.082 331.956" stroke="#3F3D56" strokeMiterlimit="10"/>
<path id="Vector_19" d="M368.198 165.194H459.63" stroke="#3F3D56" strokeMiterlimit="10"/>
<path id="Vector_20" d="M513.378 146.905H418.587V182.531H513.378V146.905Z" fill="#63C1FF"/>
<path id="Vector_21" d="M513.501 164.718H573.379" stroke="#3F3D56" strokeMiterlimit="10"/>
<path id="Vector_22" d="M418.133 164.718L362.638 54.456" stroke="#3F3D56" strokeMiterlimit="10"/>
</g>
<g id="Desk">
<path id="Vector_24" d="M746.984 379.8L704.078 594.771H694.79L693.768 593.183L690.809 588.579L727.964 387.761L733.604 385.399L746.984 379.8Z" fill="#47465A"/>
<path id="Vector_25" opacity="0.1" d="M733.604 385.399L693.768 593.183L690.809 588.579L727.964 387.761L733.604 385.399Z" fill="black"/>
<path id="Vector_26" d="M811.564 672.621L800.533 676.177L797.852 677.044L794.313 669.082L733.604 392.149L739.916 390.734L753.177 387.761L811.564 672.621Z" fill="#47465A"/>
<path id="Vector_27" opacity="0.1" d="M800.533 676.177L797.852 677.044L794.313 669.082L733.604 392.149L739.916 390.734L800.533 676.177Z" fill="black"/>
<path id="Vector_28" d="M285.636 379.8L275.905 658.466L264.753 660.943L263.962 661.12L260.865 655.37L267.058 382.453L273.016 381.604L285.636 379.8Z" fill="#47465A"/>
<path id="Vector_29" opacity="0.1" d="M273.016 381.604L264.753 660.943L263.962 661.12L260.865 655.37L267.058 382.453L273.016 381.604Z" fill="black"/>
<path id="Vector_30" d="M343.581 757.106L332.045 759.096L330.753 759.317L327.215 751.798L271.039 387.761L272.658 387.27L281.213 384.665L343.581 757.106Z" fill="#47465A"/>
<path id="Vector_31" opacity="0.1" d="M332.045 759.096L330.753 759.317L327.215 751.798L271.039 387.761L272.658 387.27L332.045 759.096Z" fill="black"/>
<path id="Vector_32" d="M587.746 357.241L630.697 412.123C632.169 414.004 634.1 415.475 636.305 416.394C638.509 417.314 640.913 417.651 643.286 417.373L819.604 396.723C822.994 396.326 826.12 394.698 828.389 392.148C830.658 389.597 831.911 386.303 831.911 382.89V342.202L587.746 357.241Z" fill="#7A799C"/>
<path id="Vector_33" d="M732.388 366.088H744.33V383.324C744.33 384.501 743.863 385.63 743.031 386.462C742.198 387.294 741.07 387.762 739.893 387.762H736.825C735.648 387.762 734.519 387.294 733.687 386.462C732.855 385.63 732.388 384.501 732.388 383.324V366.088Z" fill="#47465A"/>
<path id="Vector_34" d="M271.039 392.185L313.99 447.067C315.462 448.948 317.393 450.419 319.598 451.338C321.802 452.258 324.206 452.595 326.579 452.317L502.896 431.667C506.287 431.27 509.413 429.642 511.682 427.092C513.951 424.541 515.204 421.247 515.204 417.833V377.146L271.039 392.185Z" fill="#7A799C"/>
<path id="Vector_35" d="M415.68 401.032H427.623V418.268C427.623 419.445 427.156 420.574 426.323 421.406C425.491 422.238 424.363 422.706 423.186 422.706H420.118C418.941 422.706 417.812 422.238 416.98 421.406C416.148 420.574 415.68 419.445 415.68 418.268V401.032H415.68Z" fill="#47465A"/>
<path id="Vector_36" d="M320.385 419.981L844.844 358.89C846.492 358.698 848.034 357.98 849.241 356.843C850.448 355.705 851.257 354.208 851.546 352.575C851.835 350.942 851.59 349.258 850.847 347.775C850.104 346.292 848.903 345.088 847.422 344.342L753.605 297.16C735.127 287.867 714.379 284.045 693.802 286.143L227.861 333.652C226.195 333.827 224.611 334.467 223.292 335.499C221.973 336.531 220.971 337.914 220.401 339.489C219.832 341.065 219.717 342.769 220.071 344.406C220.425 346.043 221.233 347.548 222.402 348.747L276.661 404.416C282.276 410.177 289.137 414.575 296.716 417.273C304.295 419.972 312.392 420.898 320.385 419.981V419.981Z" fill="#47465A"/>
<path id="Vector_37" d="M425.813 244.494L432.268 341.317L573.37 327.605L567.756 232.956C567.68 231.688 567.352 230.448 566.791 229.309C566.229 228.17 565.445 227.155 564.485 226.324C563.526 225.493 562.409 224.862 561.202 224.468C559.994 224.075 558.72 223.927 557.455 224.033L434.513 234.374C432.026 234.584 429.72 235.761 428.093 237.654C426.466 239.547 425.647 242.003 425.813 244.494Z" fill="#7A799C"/>
<path id="Vector_38" d="M620.7 355.471L480.039 370.816L474.731 371.395L433.595 344.856V339.105L573.37 326.517L620.7 355.471Z" fill="#7A799C"/>
<path id="Vector_39" opacity="0.1" d="M480.039 370.816L474.731 371.395L433.595 344.856V339.707L480.039 370.816Z" fill="black"/>
<path id="Vector_40" d="M435.364 243.562L440.23 329.816L564.082 318.316L559.658 234.273L435.364 243.562Z" fill="#9493B6"/>
<path id="Vector_41" opacity="0.1" d="M509.233 353.702L524.272 363.876L569.832 359.452L554.35 349.721L509.233 353.702Z" fill="black"/>
<path id="Vector_42" d="M447.749 341.317L466.769 355.471L588.41 343.971L566.736 329.816L447.749 341.317Z" fill="#9493B6"/>
</g>
<g id="Chair">
<path id="Vector_43" d="M904.587 362.101L898.977 359C898.977 359 892.921 363.458 887.987 382.24C883.053 401.021 897.208 382.443 897.208 382.443L903.4 369.616L904.587 362.101Z" fill="#47465A"/>
<path id="Vector_44" d="M844.571 534.162C844.571 534.162 838.82 561.144 836.166 568.664C834.866 572.344 828.585 575.71 821.623 578.284C815.45 580.584 809.059 582.251 802.549 583.261C795.03 584.145 698.602 569.106 651.715 558.933C620.129 552.081 616.046 544.221 616.745 539.824C616.97 538.515 617.598 537.309 618.541 536.374C618.541 536.374 664.985 504.968 681.351 489.929C697.718 474.89 730.008 486.833 765.394 493.026C800.78 499.218 844.571 534.162 844.571 534.162Z" fill="#47465A"/>
<path id="Vector_45" d="M898.977 359C898.977 359 920.651 373.155 847.667 402.348C847.667 402.348 807.998 424.715 813.162 516.851C813.407 520.995 814.668 525.014 816.835 528.553C824.365 540.939 840.501 572.291 813.608 576.183V583.261C813.608 583.261 862.264 558.932 867.572 538.143C872.88 517.354 890.573 401.906 899.419 391.29C908.266 380.674 914.016 363.866 898.977 359Z" fill="#47465A"/>
<path id="Vector_46" opacity="0.1" d="M821.623 578.284C815.45 580.584 809.059 582.251 802.549 583.261C795.03 584.145 698.602 569.106 651.715 558.932C620.129 552.081 616.046 544.221 616.745 539.824C637.508 546.923 704.928 569.455 729.123 571.318C757.874 573.529 812.803 579.678 816.965 575.498C818.566 573.888 820.207 575.586 821.623 578.284Z" fill="black"/>
<path id="Vector_47" d="M569 762.404L611.021 570.433C611.021 570.433 618.541 550.086 635.792 550.971L651.715 558.933C651.715 558.933 625.176 552.74 619.425 573.087C613.675 593.434 576.962 762.404 576.962 762.404H569Z" fill="#47465A"/>
<path id="Vector_48" d="M669.851 674.823L686.659 557.606H694.179L676.928 674.823H669.851Z" fill="#47465A"/>
<path id="Vector_49" d="M830.858 789.386L814.935 606.262C814.935 606.262 814.935 590.78 822.897 583.261C830.858 575.741 856.562 554.29 856.562 554.29C856.562 554.29 863.148 552.74 868.456 567.779C873.764 582.818 910.478 733.21 910.478 733.21L903.4 736.306L857.84 562.913L826.435 592.549C826.435 592.549 820.243 603.608 823.339 613.781C826.435 623.955 837.936 789.386 837.936 789.386H830.858Z" fill="#47465A"/>
<path id="Vector_50" d="M781.76 578.838L821.569 590.338L830.416 581.934L807.415 578.838H781.76Z" fill="#47465A"/>
</g>
<g id="person">
<path id="Vector_51" d="M638.162 478.362C638.162 478.362 597.162 495.362 610.162 505.362C623.162 515.362 650.162 493.362 649.162 490.362C648.162 487.362 653.162 480.362 653.162 480.362L638.162 478.362Z" fill="#9F616A"/>
<path id="Vector_52" d="M674.162 344.362L661.162 356.362L658.162 391.362L648.162 428.362C648.162 428.362 631.162 463.362 634.162 470.362C637.162 477.362 631.162 479.362 631.162 479.362L654.162 488.362C654.162 488.362 679.162 460.362 679.162 454.362C679.162 448.362 674.162 344.362 674.162 344.362Z" fill="#575A88"/>
<path id="Vector_53" opacity="0.1" d="M674.162 344.362L661.162 356.362L658.162 391.362L648.162 428.362C648.162 428.362 631.162 463.362 634.162 470.362C637.162 477.362 631.162 479.362 631.162 479.362L654.162 488.362C654.162 488.362 679.162 460.362 679.162 454.362C679.162 448.362 674.162 344.362 674.162 344.362Z" fill="black"/>
<path id="Vector_54" d="M551.162 704.362C551.162 704.362 556.162 727.362 557.162 729.362C558.162 731.362 538.162 738.362 538.162 738.362L514.162 721.362C514.162 721.362 524.162 699.362 524.162 695.362L551.162 704.362Z" fill="#575A88"/>
<path id="Vector_55" d="M682.162 480.362L675.162 488.362C675.162 488.362 530.162 506.362 527.162 534.362C524.162 562.362 503.162 694.362 515.162 698.362C527.162 702.362 558.162 717.362 558.162 709.362C558.162 701.362 578.162 591.362 574.162 571.362C574.162 571.362 747.162 549.362 751.162 524.362C755.162 499.362 747.162 488.362 747.162 488.362L682.162 480.362Z" fill="#2F2E41"/>
<path id="Vector_56" opacity="0.1" d="M682.162 480.362L675.162 488.362C675.162 488.362 530.162 506.362 527.162 534.362C524.162 562.362 503.162 694.362 515.162 698.362C527.162 702.362 558.162 717.362 558.162 709.362C558.162 701.362 578.162 591.362 574.162 571.362C574.162 571.362 747.162 549.362 751.162 524.362C755.162 499.362 747.162 488.362 747.162 488.362L682.162 480.362Z" fill="black"/>
<path id="Vector_57" d="M553.162 727.362C553.162 727.362 557.162 718.362 559.162 721.362C561.162 724.362 568.162 759.362 561.162 759.362C554.162 759.362 490.162 775.362 481.162 773.362C472.162 771.362 455.162 774.362 457.162 764.362C459.162 754.362 469.162 753.362 469.162 753.362C469.162 753.362 506.162 731.362 508.162 720.362C510.162 709.362 521.162 715.362 521.162 715.362V722.362C521.162 722.362 542.162 738.362 553.162 727.362Z" fill="#2F2E41"/>
<path id="Vector_58" d="M743.162 268.362C743.162 268.362 736.162 302.362 751.162 305.362C766.162 308.362 732.162 328.362 732.162 328.362L710.162 340.362L694.162 324.362C694.162 324.362 708.662 294.862 697.662 272.862C686.662 250.862 743.162 268.362 743.162 268.362Z" fill="#9F616A"/>
<path id="Vector_59" d="M694.162 324.362L710.162 340.362L732.162 328.362C732.162 328.362 766.162 308.362 751.162 305.362C741.512 303.432 740.972 288.672 741.802 278.392C742.073 275.026 742.527 271.678 743.162 268.362C743.162 268.362 686.662 250.862 697.662 272.862C699.333 276.259 700.493 279.885 701.102 283.622C704.472 303.392 694.162 324.362 694.162 324.362Z" fill="#9F616A"/>
<path id="Vector_60" d="M638.162 733.362V756.362H600.162L597.162 747.362L601.162 723.362L638.162 733.362Z" fill="#575A88"/>
<path id="Vector_61" d="M795.162 500.362C795.162 500.362 795.162 559.362 771.162 561.362C747.162 563.362 661.162 574.362 661.162 574.362C661.162 574.362 656.162 672.362 647.162 704.362C638.162 736.362 646.162 738.362 638.162 740.362C630.162 742.362 596.162 735.362 597.162 728.362C598.162 721.362 602.162 544.362 602.162 544.362C602.162 544.362 593.162 506.362 713.162 488.362L795.162 500.362Z" fill="#2F2E41"/>
<path id="Vector_62" d="M635.162 749.362C635.162 749.362 641.162 736.362 642.162 740.362C643.162 744.362 655.162 779.362 643.162 782.362C631.162 785.362 593.162 801.362 568.162 800.362C543.162 799.362 540.162 798.362 540.162 798.362C540.162 798.362 533.162 788.362 542.162 782.362C551.162 776.362 588.162 741.362 590.162 737.362C592.162 733.362 607.162 730.362 607.162 737.362C607.162 744.362 628.162 758.362 635.162 749.362Z" fill="#2F2E41"/>
<path id="Vector_63" opacity="0.1" d="M697.662 272.862C699.333 276.259 700.493 279.885 701.102 283.622C706.082 286.632 709.412 285.862 715.662 285.862C724.932 285.862 728.672 274.012 734.662 267.862C735.132 262.152 743.162 268.362 743.162 268.362C743.162 268.362 686.662 250.862 697.662 272.862Z" fill="black"/>
<path id="Vector_64" d="M718.162 285.362C699.937 285.362 685.162 270.587 685.162 252.362C685.162 234.137 699.937 219.362 718.162 219.362C736.388 219.362 751.162 234.137 751.162 252.362C751.162 270.587 736.388 285.362 718.162 285.362Z" fill="#9F616A"/>
<path id="Vector_65" d="M661.162 356.362C661.162 356.362 666.162 392.362 669.162 405.362C672.162 418.362 676.162 478.362 678.162 486.362C680.162 494.362 692.162 496.362 716.162 493.362C717.669 493.172 719.184 493.059 720.702 493.022C735.482 492.552 752.392 498.082 768.042 502.422C777.042 504.922 785.632 507.022 793.162 507.362C804.562 507.882 807.902 495.242 807.512 481.562C807.142 468.832 803.532 455.182 800.162 450.362C794.092 441.682 800.062 363.752 797.822 338.052V338.042C797.482 334.122 796.952 331.422 796.162 330.362C790.162 322.362 751.162 302.362 746.792 303.172C742.432 303.982 705.162 327.362 705.162 327.362C696.162 327.362 696.452 317.602 696.452 317.602C696.452 317.602 690.162 329.362 678.162 332.362C666.162 335.362 661.162 356.362 661.162 356.362Z" fill="#575A88"/>
<path id="Vector_66" d="M728.009 486.005C728.009 486.005 663.593 488.056 674.878 495.709C686.162 503.362 736.162 510.362 737.162 500.362C738.162 490.362 728.009 486.005 728.009 486.005Z" fill="#9F616A"/>
<path id="Vector_67" opacity="0.1" d="M720.702 493.022C720.492 496.862 730.822 505.512 734.162 511.362C736.012 514.602 750.332 510.332 768.042 502.422C781.633 496.322 794.816 489.354 807.512 481.562C807.142 468.832 803.532 455.182 800.162 450.362C794.092 441.682 800.062 363.752 797.822 338.052V338.042C797.288 337.771 796.733 337.543 796.162 337.362H784.162C743.162 356.362 790.162 438.362 790.162 438.362C790.162 438.362 728.162 489.362 722.162 491.362C721.769 491.436 721.411 491.64 721.147 491.941C720.882 492.242 720.726 492.622 720.702 493.022V493.022Z" fill="black"/>
<path id="Vector_68" d="M784.162 331.362H796.162C796.162 331.362 807.162 334.362 816.162 354.362C825.162 374.362 858.162 436.362 829.162 460.362C800.162 484.362 738.162 512.362 734.162 505.362C730.162 498.362 716.162 487.362 722.162 485.362C728.162 483.362 790.162 432.362 790.162 432.362C790.162 432.362 743.162 350.362 784.162 331.362Z" fill="#575A88"/>
<path id="Vector_69" d="M723.239 237.006C723.239 237.006 721.791 216.959 703.925 225.133C686.059 233.307 687.225 210.776 692.624 206.795C698.023 202.813 697.852 201.828 717.598 204.492C737.344 207.156 775.68 211.669 763.284 251.372C750.888 291.076 739.662 280.862 739.662 280.862C739.662 280.862 749.515 245.134 739.532 251.941L729.548 258.748L724.28 257.632L723.239 237.006Z" fill="#2F2E41"/>
<path id="Vector_70" d="M749.881 208.479L742.486 207.113L735.038 247.431L746.838 249.611L753.473 213.698C753.688 212.53 753.431 211.324 752.758 210.345C752.084 209.366 751.05 208.695 749.881 208.479V208.479Z" fill="#63C1FF"/>
<path id="Vector_71" d="M739.662 270.862C731.378 270.862 724.662 264.146 724.662 255.862C724.662 247.578 731.378 240.862 739.662 240.862C747.946 240.862 754.662 247.578 754.662 255.862C754.662 264.146 747.946 270.862 739.662 270.862Z" fill="#63C1FF"/>
<path id="Vector_72" d="M739.662 264.862C734.692 264.862 730.662 260.833 730.662 255.862C730.662 250.891 734.692 246.862 739.662 246.862C744.633 246.862 748.662 250.891 748.662 255.862C748.662 260.833 744.633 264.862 739.662 264.862Z" fill="#F2F2F2"/>
<path id="Vector_73" d="M739.662 258.862C738.005 258.862 736.662 257.519 736.662 255.862C736.662 254.205 738.005 252.862 739.662 252.862C741.319 252.862 742.662 254.205 742.662 255.862C742.662 257.519 741.319 258.862 739.662 258.862Z" fill="#63C1FF"/>
</g>
</svg>
        </div>
    )
}
